import React from "react";

import headerLogo from "../assets/images/logo.png";

import "../styles/screenHeaderBar-styles.css";

const ScreenHeaderBar = () => {
  return (
    <div className="screen-header-container">
      <img src={headerLogo} alt="" className="logo" />
    </div>
  );
};

export default ScreenHeaderBar;
