import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import playerImage from "../assets/images/playerImage.webp";
import androidIcon from "../assets/images/androidIcon.png";
import mobileTitle from "../assets/images/mobileTitle.png";
import desktopTitle from "../assets/images/desktopTitle.png";

import {
  websiteMetaTagDescription,
  websiteTitle,
} from "../constants/htmlStrings";

import "../styles/home-styles.css";
import QRCode from "../components/QRCode";

const LP1 = () => {
  let url = window.location.href;
  const [branchLink, setBranchLink] = useState(
    "https://tc2e9.test-app.link/VE95JEnEjqb"
  );

  useEffect(() => {
    const index = url.indexOf("?");
    if (index !== -1) {
      const queryParams = url.substring(index, url.length);
      const link = branchLink + queryParams;
      setBranchLink(link);
    }
  }, [url]);
  return (
    <div className="main-container">
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteMetaTagDescription} />
      </Helmet>
      {/* <ScreenHeaderBar /> */}
      <div className="container">
        <div className="text-container">
          <img src={mobileTitle} alt="tagline" className="tagline" />
          <img src={desktopTitle} alt="tagline" className="desktop-tagline" />
          <QRCode />
        </div>
        <img src={playerImage} alt="playerImage" className="player-image" />
        <div className="button-tagline">Download Buystars PRO App.</div>
        <a
          href={branchLink}
          className="button-container"
          id="download-mobile-button"
          // target="_blank"
          // rel="noreferrer"
          download
        >
          <img src={androidIcon} alt="" className="mobile-download-button" />
          <div className="mobile-download-text">Download App</div>
        </a>
      </div>
      <div className="sub-container">
        <div className="link-container">
          <Link to="/about-us" className="link">
            <div className="link-text">About Us</div>
          </Link>
          <div className="separator" />
          <Link to="/terms-of-service" className="link">
            <div className="link-text">Terms Of Service</div>
          </Link>
          <div className="separator" />
          <Link to="/privacy-policy" className="link">
            <div className="link-text">Privacy Policy</div>
          </Link>
        </div>
        {/* <div className="copyright-container">
          <div className="copyright-text">Copyright © 2022</div>
          <div className="mobile-separator" />
          <div className="copyright-owner-text">fangame11.com</div>
        </div> */}
      </div>
    </div>
  );
};

export default LP1;
