import React from "react";

import { Navigate, Route, Routes } from "react-router";
import { Home, AboutUs, TermsOfService, PrivacyPolicy, LP1 } from "../pages";

const RootRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/lp1" element={<LP1 />} />
      <Route
        path="*"
        element={<Navigate to={"/"} />}
      />
    </Routes>
  );
};

export default RootRouter;
