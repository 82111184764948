import React, {useEffect} from "react";
import { Helmet } from "react-helmet";

import ScreenHeaderBar from "../components/ScreenHeaderBar";
import {
  aboutUsHtmlString,
  websiteMetaTagDescription,
  websiteTitle,
} from "../constants/htmlStrings";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteMetaTagDescription} />
      </Helmet>
      <div>
        <ScreenHeaderBar />
        <div dangerouslySetInnerHTML={{ __html: aboutUsHtmlString }} />
      </div>
    </>
  );
};

export default AboutUs;
